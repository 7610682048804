<template>
  <BaseDialog title="新增排班" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" label-position="top">
      <BaseElFormItem label="選擇排班新增方式" prop="mode">
        <BaseElRadioGroup v-model="formData.mode">
          <BaseElRadio :label="editModeConfig.sketch.value">逐筆新增</BaseElRadio>
          <BaseElRadio :label="editModeConfig.batch.value">批次新增</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { editModeConfig } from '@/config/shiftCalendar'

export default defineComponent({
  name: 'SelectEditShiftScheduleModeModal',
  components: { BaseDialog },
  setup (props, { emit }) {
    const { initFormData, formRef, formData } = useBaseForm()
    initFormData({
      mode: editModeConfig.sketch.value,
    })
    const formRules = computed(() => {
      return {
        mode: [noEmptyRules()],
      }
    })
    const onConfirm = () => {
      emit('confirm', formData.mode)
      emit('close')
    }
    return { formRef, formData, formRules, onConfirm, editModeConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
